.Header {
	position: fixed;
	text-align: center;
	background-color: #333;
	height: 60px;
	padding: 0 20px 0 10px;
	color: white;
	width: calc(100% - 30px);
	z-index: 999;
	top: 0;
}

.Header {
	box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.Logo img {
	animation: App-logo-spin infinite 20s linear;
	height: 30px;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.Header .Menu {
	margin: 0;
	padding: 0;
	list-style: none;
}

.Header .Menu li .truncate {
	width: 220px;
	font-size:24pt;
	overflow:hidden;
	white-space:nowrap;
	text-overflow: ellipsis;
}

.Header .Menu li a {
	color: #CCC;
	text-decoration: none;
}

.Header .Menu li a:hover {
	color: #EEE;
}

.Header .text-icon-header {
	color: white !important;
}
