/*
Diseño tablas de caja
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

table.desing-acresco-xml {
	/*background-color: white;*/
	text-align: left;
	border-collapse: collapse;
	width: 100%;
	min-width: 200px;
}

.desing-acresco-xml th {
	padding: 4px;
	font-size: 13px !important;
	color: rgb(51, 51, 51);
}
.desing-acresco-xml td {
	padding: 4px;
	font-size: 13px !important;
	color: #333;
}

.desing-acresco-xml thead {
	background-color: rgba(14, 84, 157, 0.16);
	border-bottom: solid 2px rgba(14, 84, 157, 0.48);
	font-size: 16px !important;
}


.desing-acresco-xml td {
	border: 1px solid #c3c3c3;
	border-style: dashed;
	background-color: transparent;
}

.desing-acresco-xml tr:nth-child(even) {
	/*background-color: rgba(200, 200, 200, 0.05);*/
	color: black;
}

.desing-acresco-xml tr:hover td {
	background-color: #f0f0f0;
	color: black;
}

/*
Diseño tablas de excel
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

table.desing-acresco-excel-export {
	/*background-color: white;*/
	text-align: left;
	border-collapse: collapse;
	width: 100%;
	min-width: 200px;
}

.desing-acresco-excel-export th {
	padding: 4px;
	font-size: 11px !important;
	color: rgb(51, 51, 51);
}
.desing-acresco-excel-export td {
	padding: 4px;
	font-size: 11px !important;
	color: #333;
}

.desing-acresco-excel-export thead {
	background-color: rgba(14, 84, 157, 0.16);
	border-bottom: solid 2px rgba(14, 84, 157, 0.48);
	font-size: 16px !important;
}


.desing-acresco-excel-export td {
	border: 1px solid #c3c3c3;
	border-style: dashed;
	background-color: transparent;
}

.desing-acresco-excel-export tr:nth-child(even) {
	/*background-color: rgba(200, 200, 200, 0.05);*/
	color: black;
}

.desing-acresco-excel-export tr:hover td {
	background-color: #f0f0f0;
	color: black;
}

.desing-acresco-excel-export .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
	font-size: 13px !important;
}
