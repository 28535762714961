.App {
	text-align: center;
}

.App-logo {
	animation: App-logo-spin infinite 20s linear;
	height: 80px;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.Container {
	top: 60px !important;
	position: relative !important;
	overflow: hidden auto !important;
	background: white;
	height: calc(100vh - 60px) !important;
	min-height: calc(100vh - 60px) !important;
}

.Container.Menu-2 {
	padding-left: 70px !important;
}

.ContaineViewComponet {
	padding: 20px;
	min-height: calc(100vh - 150px) !important;
}

.ContaineViewComponet.NoFooter {
	padding: 20px;
	min-height: calc(100vh - 60px) !important;
}

.Menu-Shadow {
	box-shadow: 0px 8px 4px 4px rgba(0, 0, 0, 0.2);
}
