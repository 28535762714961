
.timbrado_facturacion.swal-text {
	text-align: justify !important;
}

.fondoanimalista {
	background-image: linear-gradient(to top, rgba(252, 252, 252, 0.05), rgba(142,30,50, 1)), url("../../../assets/img/fondos/iStock-1336045232.jpeg");
	background-position: center center;
	background-repeat: no-repeat;
	background-attachment: initial;
	background-size: cover;
	height: 100vh;
}


/*
Diseño tablas de caja
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

table.desing-facturacion-estadistica {
	/*background-color: white;*/
	text-align: left;
	border-collapse: collapse;
	width: 100%;
	min-width: 200px;
}

.desing-facturacion-estadistica th {
	padding: 4px;
	font-size: 14px !important;
	color: rgb(51, 51, 51);
}
.desing-facturacion-estadistica td {
	padding: 4px;
	font-size: 14px !important;
	color: #333;
}

.desing-facturacion-estadistica thead {
	background-color: rgba(14, 84, 157, 0.16);
	border-bottom: solid 2px rgba(14, 84, 157, 0.48);
	font-size: 16px !important;
}


.desing-facturacion-estadistica td {
	border: 1px solid #c3c3c3;
	border-style: dashed;
	background-color: transparent;
}

.desing-facturacion-estadistica tr:nth-child(even) {
	/*background-color: rgba(200, 200, 200, 0.05);*/
	color: black;
}

.desing-facturacion-estadistica tr:hover td {
	background-color: #f0f0f0;
	color: black;
}

.MuiGrid-root .MuiGrid-container .MuiGrid-spacing-xs-8 {
	width: 100% !important;
}

.hover-opcion:hover {
	background-color: #f0f0f0 !important;
}

.MuiTypography-body1 {
	font-size: 13px !important;
}
