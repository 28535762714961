.content-image {
	height: calc(100vh - 170px) !important;
}

/*
Diseño tablas de caja
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

table.desing-acresco-home {
	/*background-color: white;*/
	text-align: left;
	border-collapse: collapse;
	width: 100%;
	min-width: 200px;
}

.desing-acresco-home th {
	padding: 4px;
	font-size: 11px !important;
	color: #333;
	border: 1px solid #c3c3c3;
	border-style: dashed;
}
.desing-acresco-home td {
	padding: 4px;
	font-size: 11px !important;
	color: #333;
}

.desing-acresco-home thead {
	border: 1px solid #c3c3c3;
	border-style: dashed;
	font-size: 12px !important;
}


.desing-acresco-home td {
	border: 1px solid #c3c3c3;
	border-style: dashed;
	background-color: transparent;
}

.desing-acresco-home tr:nth-child(even) {
	/*background-color: rgba(200, 200, 200, 0.05);*/
	color: black;
}

.desing-acresco-home tr:hover td {
	background-color: #f0f0f0;
	color: black;
}


.home-gradient0 {
	background: rgba(173, 169, 150, 0.3);  /* fallback for old browsers */
	background: -webkit-linear-gradient(to right, rgba(234, 234, 234, 0.3), rgba(219, 219, 219, 0.3), rgba(242, 242, 242, 0.31), rgba(173, 169, 150, 0.31));  /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to right, rgba(234, 234, 234, 0.3), rgba(219, 219, 219, 0.31), rgba(242, 242, 242, 0.31), rgba(173, 169, 150, 0.31)); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.home-gradient1 {
	background: #eeeeee;  /* fallback for old browsers */
	background: -webkit-linear-gradient(to right, #f05053, #eeeeee);  /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to right, #f05053, #eeeeee); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.button {
	transition-duration: 0.4s;
}

.home-gradient5 {
	background-color: rgba(95, 148, 227, 0.5);
}

.home-gradient6 {
	background-color: rgba(127, 0, 255, 0.3);
}

.home-gradient7 {
	background-color: rgba(72, 177, 191, 0.31);
}

.home-gradient8 {
	background-color: rgba(46, 254, 198, 0.31);
}

.home-gradient9 {
	background-color: rgba(15, 155, 15, 0.42);
}

.home-gradient11 {
	background-color: rgba(152, 245, 245, 0.3);
}

.home-gradient15 {
	background-color: rgba(136, 220, 139, 0.56);
}

.home-gradient21 {
	background-color: rgba(235, 87, 87, 0.31);
}

.home-gradient23 {
	background-color: rgba(28, 27, 27, 0.31);
}

.btn-dash-home-0,
.btn-dash-home-1,
.btn-dash-home-5,
.btn-dash-home-6,
.btn-dash-home-7,
.btn-dash-home-8,
.btn-dash-home-9,
.btn-dash-home-11,
.btn-dash-home-15,
.btn-dash-home-21,
.btn-dash-home-23 {
	background-image: url("../../../assets/img/icons/background-btns.jpg");
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	border-radius: 6px;
	box-shadow: 1px 1px 8px 1px rgba(88, 198, 255, 0.8);
	/*cursor: pointer;*/
}

.Home .MuiTableHead-root {
	background: rgba(169, 169, 169, 0.6);
}

.Home .MuiTableHead-root th {
	color: white;
	font-size: 12px !important;
}

.cursorcolor:hover {
	color: #2f73ff;
	text-decoration: underline;
}
