.color-icon-menu {
	color: white !important;
}

.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorLeft, .Menu-Shadow {
	/*background-image: url("../../../assets/img/menu/menu.jpg"), linear-gradient(to bottom, #8E1E32, white);*/
	/*background: url("../../../assets/img/menu/menu.jpg"), linear-gradient(0deg, rgba(106,0,14,1) 0, rgba(106,0,14,1) 50%, rgba(106,0,14,1) 100%);*/
	background-image: linear-gradient(to top, rgba(252, 252, 252, 0.40), rgb(51, 51, 51)), url("../../../assets/img/menu/menu.jpg");
	/*background-image: -webkit-gradient(to top, rgba(245, 246, 252, 0.52), rgba(142,30,50, 1)), url("../../../assets/img/menu/menu.jpg");
	background-image: -moz-linear-gradient(to top, rgba(245, 246, 252, 0.52), rgba(142,30,50, 1)), url("../../../assets/img/menu/menu.jpg");*/
	background-position: center center;
	background-repeat: no-repeat;
	background-attachment: initial;
	background-size: cover;
	height: 100vh;
}

.color-icon-menu {
	color: white !important;
}

.mat-list {
	/*flex-grow: 1;
	border: 3px solid green;*/
	overflow: auto;
	height: 100%;
}
