.content-image {
	height: calc(100vh - 170px) !important;
}

.react-multi-email {
	border-left: none;
	border-top: none;
	border-right: none;
	border-bottom: 1px solid rgba(34, 36, 38, 0.5);
	border-radius: 0px;
}

.react-multi-email.focused {
	border-color: #3f51b5;
	border-bottom: 2px solid #3f51b5;
}
